import React, { useRef } from "react";
import "./Join.css";
import emailjs from "@emailjs/browser";

const Join = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ua0d3vf",
        "template_s8331hs",
        form.current,
        "DdzXKPAHQtPVkqYtd"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">READY TO</span>
          <span>LEVEL UP</span>
        </div>
        <div>
          <span>YOUR BODY </span>
          <span className="stroke-text">WITH US ?</span>
        </div>
      </div>
      <div className="right-j">
        <div>
          <form ref={form} action="" className="email-container">
            <input
              type="email"
              placeholder="Enter your Email address here"
              name="user_email"
            />
            <button className="btn btn-j" onClick={sendEmail}>
              Join now
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Join;
