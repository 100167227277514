import React from "react";
import "./Plans.css";
import {plansData} from "../../data/plansData";
import whiteTick from '../../assets/whiteTick.png';

const Plans = () => {
  return (
    <div className="plans-container">
      <div className="blur" id="plans_blur_1"></div>
      <div className="blur" id="plans_blur_2"></div>

      <div className="programs-header" id="__Plan" style={{ gap: "2rem" }}>
        <span className="stroke-text">Ready to start</span>
        <span>your journey</span>
        <span className="stroke-text">now with us</span>
      </div>
      <div className="plans">
        {plansData.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>{plan.price}</span>
            <div className="features">
                {plan.features.map((feature,i)=>(
                    <div className="feature" key={i}>
                        <img src={whiteTick} alt="" />
                        <span key={i}>{feature}</span>
                    </div>
                ))}
            </div>
            <div><span>See More Benefits </span></div>
            <button className="btn">
                Join now
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
